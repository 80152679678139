import React from "react"
import FormSteps from "../formSteps/formSteps"
import tw, { css } from "twin.macro"

const LandingsFormularioRecibePresupuesto = ({ content }) => {
  const { title } = content.data
  return (
    <div id="presupuesto" css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden bg-[#212121]`}>
      <div css={tw`px-5 md:px-9 `}>
        <div css={tw`max-w-screen-xl my-0 mx-auto px-0 text-white pt-6 md:pt-8 pb-6 md:pb-8 lg:pb-12 `}>
          {title && <h3 css={tw`text-xl sm:text-2xl md:text-4xl font-bold`}>
            {title}
          </h3>}
          <div css={tw`md:flex md:justify-between md:gap-8 mt-6`}>
            <div css={tw`flex flex-col justify-between md:w-1/2`}>
              <p css={tw`text-sm lg:text-base leading-[1.8em] pb-8`}>
                Al completar este formulario, nuestro distribuidor más cercano a ti te contactará para saber más de tu proyecto y preparar un presupuesto a tu medida.
              </p>
              <ul css={tw`flex flex-col gap-4 text-base lg:text-lg pb-8`}>
                <li css={twLi}>
                  <div css={twSvgContainer}><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={51}
                    height={18}
                    fill="none"
                  >
                    <path
                      fill="#4B4B4B"
                      d="M39.815 2c-.246.247-.16.54.209.953l.738-.739c-.412-.374-.7-.46-.952-.208L39.815 2Z"
                    />
                    <path
                      fill="#8C8C8C"
                      d="m37.485 3.54.605-.604.973.974.465-.465c-.556-.626-.711-1.316-.144-1.883s1.252-.412 1.883.144L42.978 0H27.927l-10.2 10.2 7.525 7.526L37.827 5.15l-.342-1.61Z"
                    />
                    <path fill="#4B4B4B" d="m38.191 4.054.134.6.246-.247-.363-.363-.017.01Z" />
                    <path
                      fill="#fff"
                      d="M0 7.526 7.526 0l17.726 17.726H10.2L0 7.526ZM39.817 2c-.273.273-.145.605.342 1.092.487.486.813.62 1.091.342.278-.278.145-.604-.342-1.091s-.813-.615-1.086-.343h-.005ZM38.191 4.054l.284 1.257.01.011.503-.497-.78-.781-.017.01Z"
                    />
                    <path
                      fill="#fff"
                      d="M50.401 7.424 42.977 0H27.926l14.95 14.95-.904-8.43 8.43.904Zm-10.574-1.76-.407-.406-.984.984-.47-.47-.477-2.231.605-.605 1.39 1.391.268-.267.433.433-.267.267.406.407-.497.492v.005Zm-.171-2.075c-.663-.663-.883-1.412-.273-2.022.61-.61 1.358-.39 2.022.273.668.669.888 1.417.278 2.027-.61.61-1.359.39-2.027-.278Z"
                    />
                  </svg></div>
                  40 años de experiencia</li>
                <li css={twLi}>
                <div css={twSvgContainer}><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={42}
                  height={21}
                  fill="none"
                >
                  <path
                    fill="#fff"
                    d="M20.831 1.064a7.6 7.6 0 0 0-7.6 7.601c0 5.135 7.6 12.083 7.6 12.083s7.6-7.023 7.6-12.083a7.6 7.6 0 0 0-7.6-7.6Zm0 12.206a4.605 4.605 0 1 1 0-9.21 4.605 4.605 0 0 1 0 9.21Z"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M20.831 10.617a1.952 1.952 0 1 0 0-3.904 1.952 1.952 0 0 0 0 3.904Z"
                  />
                  <path
                    fill="#fff"
                    d="M4.605 0A4.602 4.602 0 0 0 0 4.605c0 3.113 4.605 7.317 4.605 7.317S9.211 7.67 9.211 4.605A4.609 4.609 0 0 0 4.605 0Zm0 7.392A2.785 2.785 0 0 1 1.82 4.605 2.785 2.785 0 0 1 4.605 1.82a2.785 2.785 0 0 1 2.787 2.786 2.785 2.785 0 0 1-2.787 2.787Z"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M4.604 5.787a1.182 1.182 0 1 0 0-2.364 1.182 1.182 0 0 0 0 2.364Z"
                  />
                  <path
                    fill="#fff"
                    d="M36.808 0a4.605 4.605 0 0 0-4.605 4.605c0 3.113 4.605 7.317 4.605 7.317s4.606-4.252 4.606-7.317c0-2.54-2.06-4.605-4.606-4.605Zm0 7.392a2.785 2.785 0 0 1-2.786-2.787 2.785 2.785 0 0 1 2.786-2.786 2.785 2.785 0 0 1 2.787 2.786 2.785 2.785 0 0 1-2.787 2.787Z"
                  />
                  <path
                    fill="#8C8C8C"
                    d="M36.807 5.787a1.182 1.182 0 1 0 0-2.364 1.182 1.182 0 0 0 0 2.364Z"
                  />
                </svg></div>
                  Más de 300 distribuidores</li>
                <li css={twLi}>
                <div css={twSvgContainer}><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={46}
                    height={16}
                    fill="none"
                  >
                    <path fill="#fff" d="M45.604 0H0v15.907h45.604V0Z" />
                    <path
                      stroke="#8C8C8C"
                      strokeMiterlimit={10}
                      strokeWidth={0.95}
                      d="M2.117 0v9.74M4.54 0v4.407M6.96 0v4.407M9.383 0v4.407M11.797 0v4.407M14.223 0v6.707M16.64 0v4.407M19.063 0v4.407M21.48 0v4.407M23.906 0v4.407M26.324 0v9.74M28.742 0v4.407M31.168 0v4.407M33.586 0v4.407M36.008 0v4.407M38.426 0v6.707M40.848 0v4.407M43.266 0v4.407"
                    />
                  </svg></div>
                  Cocinas de diseño a medida</li>
              </ul>
            </div>
            <div css={tw`md:w-1/2 mt-4 md:mt-0`}>
              <FormSteps />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
const twLi = tw`flex items-center gap-2 uppercase font-bold`
const twSvgContainer = tw`w-[52px]`

export default LandingsFormularioRecibePresupuesto
