import React, { useCallback } from "react"

import Margen from "./margen"
import Linea from "./linea"
import HomeCocinasDestacadas from "./homeCocinasDestacadas"
// import HomeEnlacesDirectosInicio from "./homeEnlacesDirectosInicio"
// import HomeTextoGrande from "./homeTextoGrande"
// import HomeTextoPequeno from "./homeTextoPequeno"
import YoutubeVideo from "./youtubeVideo"
import YoutubeVideoConTexto from "./youtubeVideoConTexto"
import Video from "./video"
import VideoConTexto from "./videoConTexto"
import TituloEnlace from "./tituloEnlace"
import TituloSeccion from "./tituloSeccion"
import Parrafo from "./parrafo"
import LandingsTresEnlacesDirectosConImagenes from "./landingsTresEnlacesDirectosConImagenes"
import LandingsFormularioRecibePresupuesto from "./landingsFormularioRecibePresupuesto"
import LandingsImagenGrandeConContenidos from "./landingsImagenGrandeConContenidos"
import ListadoCocinas from "./listadoCocinas"
import ListadoCocinasSecundarias from "./listadoCocinasSecundarias"
import ListadoCocinasSimilares from "./listadoCocinasSimilares"
import Imagen from "./imagen"
import Columnas from "./columnas"
import DescargaPdfPw from "./descargaPdfPw"
import MapaPw from "./mapaPw"
import FormularioContactoPw from "./formularioContactoPw"
import TextoEscondido from "./textoEscondido"
import TituloYParrafo from "./tituloYParrafo"
import CocinasCarousel from "./cocinasCarousel"
import AnimacionConTexto from "./animacionConTexto"
import Grid from "./grid"
import PlanificaCocina from "./planificaCocina"
import MapaContacto from "./mapaContacto"
import GrayBand from "./grayBand"
import HeroSlider from "./heroSlider"
import BlogLastPosts from "./blogLastPosts"
import CTAButtons from "./ctaButtons"
import EnlacesDirectos from "./enlacesDirectos"
import EnlacesBotones from "./enlacesBotones"
import ProjectsList from "./projectsList"
import TiendasPorCiudad from "./tiendasPorCiudad"
import CallOut from "./callOut"
import BotonPedirCita from "./botonPedirCita"
import HTMLCode from "./htmlCode"

const IS_COMMENT_HIDDEN = true

const componentByType = {
  margen: Margen,
  linea: Linea,
  home_cocinas_destacadas: HomeCocinasDestacadas,
  // home_enlaces_directos_inicio: HomeEnlacesDirectosInicio,
  youtube_video: YoutubeVideo,
  video: Video,
  titulo_seccion: TituloSeccion,
  parrafo: Parrafo,
  youtube_video_con_texto: YoutubeVideoConTexto,
  video_con_texto: VideoConTexto,
  titulo_enlace: TituloEnlace,
  landings_tres_enlaces_directos_con_imagenes: LandingsTresEnlacesDirectosConImagenes,
  landings_formulario_recibe_presupuesto: LandingsFormularioRecibePresupuesto,
  landings_imagen_grande_con_contenidos: LandingsImagenGrandeConContenidos,
  listado_cocinas: ListadoCocinas,
  listado_cocinas_secundarias: ListadoCocinasSecundarias,
  listado_cocinas_similares: ListadoCocinasSimilares,
  imagen: Imagen,
  columnas: Columnas,
  descarga_pdf: DescargaPdfPw,
  mapa_general: MapaPw,
  formulario_contacto: FormularioContactoPw,
  texto_oculto: TextoEscondido,
  planifica_cocina: PlanificaCocina,
  titulo_y_parrafo: TituloYParrafo,
  cocinas_carousel: CocinasCarousel,
  animacion_con_texto: AnimacionConTexto,
  grid: Grid,
  mapa_contacto: MapaContacto,
  gray_band: GrayBand,
  hero_slider: HeroSlider,
  blog_last_posts: BlogLastPosts,
  cta_buttons: CTAButtons,
  enlaces_directos: EnlacesDirectos,
  enlaces_botones: EnlacesBotones,
  projects_list: ProjectsList,
  tiendas_por_ciudad: TiendasPorCiudad,
  callout: CallOut,
  boton_pedir_cita: BotonPedirCita,
  htmlcode: HTMLCode,
}

const Content = ({ contents, isMounting, isExiting, pageRuta, lang }) => {
  const itemProps = {
    isMounting: isMounting,
    isExiting: isExiting,
    pageRuta: pageRuta,
  }

  const WithComment = useCallback(({ content, children }) => {
    // si estamos en entorno de desarrollo
    if (process.env.NODE_ENV === "development" && !IS_COMMENT_HIDDEN) {
      return (
        <>
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(255,255,255,0.8)",
              color: "black",
            }}
            onClick={() => {
              console.log({ content })
            }}
          >
            {content.type}
          </div>
          {children}
        </>
      )
    }
    return children
  })

  let result = []
  contents.forEach((content, i) => {
    // comprobamos si el tipo de contenido está soportado
    if (componentByType[content.type]) {
      result.push(
        <WithComment content={content} key={i}>
          {React.createElement(componentByType[content.type], {
            key: i,
            order: i,
            content: content,
            lang: lang,
            ...itemProps,
          })}
        </WithComment>
      )
    }
  })
  return result
}

export default Content
