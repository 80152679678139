//Dato de ejemplo
// {
//   "tiempoProyecto": "menos3meses",
//   "presupuesto": "20000_",
//   "descripcion": "Culpa excepteur eu aute esse id veniam consectetur deserunt occaecat. Sit mollit do ullamco reprehenderit cupidatat cillum aliquip. Ex nostrud culpa dolore amet est cillum ipsum ut aute adipisicing ex irure Lorem incidid",
//   "nombre": "NOMBRE",
//   "apellidos": "APELLIDOS",
//   "email": "email@esmiemail.com",
//   "telefono": "612345678",
//   "codigoPostal": "12345",
//   "politicaPrivacidad": true
// }


export const formSend = async ({ data, callBack, lang, statData }) => {

  const tiemposProyectosSlugs = {
    menos3meses: 'En menos de 3 meses',
    mas6meses: 'En más de 6 meses',
    sinFecha: 'No tengo fecha, estoy valorando opciones',
  }
  let tiempoProyectoMessagePrefix = ''
  if (tiemposProyectosSlugs[data.tiempoProyecto]) {
    tiempoProyectoMessagePrefix = '-- Tiempo proyecto: ' + tiemposProyectosSlugs[data.tiempoProyecto] + ' --\n\n'
  }

  const  cleanedData = {
      apellido: data.apellidos,
      codigo_postal: data.codigoPostal,
      direccion: data.direccion,
      email: data.email,
      localidad: data.localidad,
      mensaje: tiempoProyectoMessagePrefix + data.descripcion,
      motivo: 'info',
      archivorrhh: data.archivorrhh,
      nombre: data.nombre,
      presupuesto: data.presupuesto,
      telefono: data.telefono,
      tipo: 'contacto',
      idStore: '',
      archivorrhh: '',
    }


  const formData = new FormData();
  const dataOfForm = {
    ...cleanedData,
    tipo: 'contacto',
    site: 'ob',
    lang: lang,
    ...statData,
  }
  
  Object.keys(dataOfForm).forEach(key => {
    formData.append(key, dataOfForm[key]);
  });

  fetch('https://api.obcocinas.es/apiext/saveform/', {
    method: 'POST',
    body: formData,
  })
    .then((response) => {
      callBack(response?.status === 200)
      // Manejar la respuesta de la API
    })
    .catch((error) => {
      callBack(false)
      // Manejar errores de la solicitud
    })
}
