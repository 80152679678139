import React from "react"
import tw, { css } from "twin.macro"
import Image from "../imagev2"
import LandingButton from "../mini/landingButton"


const LandingsImagenGrandeConContenidos = ({ content }) => {
  const { title, texto_corto1, texto_corto2, blocks } = content.data
  if (!blocks) return null
  if (blocks.length === 0) return null
  const block = blocks[0]

  return (
    <div css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden bg-black`}>

      <div css={tw`absolute inset-0`}>
        <Image
          objectFit={"contain"}
          imageTablet={block.image}
          imageMobile={block.image_mobile}
          widths={["880px", "980px", "1200px"]}
        />
      </div>
      <div css={tw`relative px-5 md:px-9 `}>
        <div css={tw`flex flex-col justify-end gap-4 sm:gap-6 md:gap-8 max-w-screen-xl my-0 mx-auto px-0 text-white pt-6 md:pt-8 pb-3 md:pb-4 h-[400px] md:h-[500px] lg:h-[680px] pb-10 md:pb-12 lg:pb-14 `}>
          {title && <h3 css={tw`text-xl sm:text-2xl md:text-4xl font-bold  md:max-w-[50%] lg:max-w-[34%]`}>
            {title}
          </h3>}
          {texto_corto1 && texto_corto2 && (
            <div css={tw`pt-4 md:pt-6 lg:pt-12`}>
              <LandingButton href={texto_corto2} target="_blank">
                {texto_corto1}
              </LandingButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}



export default LandingsImagenGrandeConContenidos
