import React from "react"
import tw from "twin.macro"

const LandingButton = ({ href, children, action, ...props }) => {
  const style = tw`bg-white cursor-pointer border-0 text-[13px] uppercase text-black font-bold px-[14px] py-[10px] rounded-none`
  if(href) {
    return (
      <a href={href} css={style} {...props}>{children}</a>
    )
  }
  return (
    <button onClick={action} css={style} {...props}>{children}</button>
  )
}

export default LandingButton