import React from "react"
import tw, { css } from "twin.macro"
import Arrow from '../vector/arrow'
import CLink from '../cLink'
import Image from "../imagev2"


const LandingsTresEnlacesDirectosConImagenes = ({ content }) => {
  const { title, texto_corto1, blocks } = content.data
  return (
    <div css={tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden bg-black`}>
      <div css={tw`px-5 md:px-9 `}>
        <div css={tw`flex flex-col gap-4 sm:gap-6 md:gap-8 max-w-screen-xl my-0 mx-auto px-0 text-white pt-6 md:pt-8 lg:pt-12 pb-3 md:pb-4 lg:pb-12`}>
          {title && <h3 css={tw`text-xl sm:text-2xl md:text-4xl font-bold text-center`}>
            {title}
          </h3>}
          {texto_corto1 && <p css={tw`text-sm md:text-base text-center `}>
            {texto_corto1}
          </p>}
          <ul css={tw`md:flex md:justify-between`}>

            {
              content.data.blocks.map((block, i) => (
                <EnlaceDirecto
                  key={block.url + i}
                  block={block}
                />
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  )
}


const EnlaceDirecto = ({
  block,
  // activo,
}) => {

  return (

    <li
      css={tw`relative md:w-[25%]`}
    >


      <CLink
        to={block.url}
        css={tw`block pb-3 md:pb-4`}
        target="_blank"
      // onMouseEnter={() => {setHover(true)}}
      // onMouseLeave={() => {setHover(false)}}
      >
        <div css={tw`flex-1`}>
          <Image
            objectFit={"cover"}
            imageTablet={block.image}
            imageMobile={block.image_mobile}
            widths={["880px", "880px", "880px"]}
            base64={[block.image_mobile.base64, block.image.base64]}
            aspectRatioMobile={1}
          aspectRatioTablet={1}
          styles={{ flex: 1, objectFit: 'cover' }}
        />
        </div>
        <div css={tw`flex flex-1 gap-4 flex-col items-center pt-4`}>
          <div css={tw`pt-4 pb-1 text-white text-obsmall md:text-obmed font-bold text-center pb-4 `}>{block.title}</div>
          <Arrow />
        </div>

      </CLink>

    </li>
  )
}




export default LandingsTresEnlacesDirectosConImagenes
