import React, { useState, useContext, useLayoutEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as s from './styles.module.css';
import { GeneralContext } from "../../contexts/generalContext"
import { formSend } from './formSend'

const FormSteps = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const visibleSteps = 4;
  const generalContext = useContext(GeneralContext)
  const { lang, pageRuta, pageTemplate } = generalContext

  const urlForParams = typeof window !== 'undefined' ? new URL(window.location.href) : { search: '' };
  const urlParams = new URLSearchParams(urlForParams.search);
  let urlParamsList = ''
  urlParams.forEach((value, key) => {
    urlParamsList += `${key}: ${value} | `;
  });
  const statData = {
    statDataRuta: pageRuta,
    statDataTemplate: pageTemplate,
    statDataGetParams: urlParamsList,
  }

  const stepsFocus = [null, null, 'descripcion', 'nombre']

  const formik = useFormik({
    initialValues: {
      // Paso 1: Tiempo
      tiempoProyecto: '',

      // Paso 2: Presupuesto
      presupuesto: '',

      // Paso 3: Descripción
      descripcion: '',

      // Paso 4: Datos personales
      nombre: '',
      apellidos: '',
      email: '',
      telefono: '',
      codigoPostal: '',
      direccion: '',
      localidad: '',
      politicaPrivacidad: false
    },
    validationSchema: Yup.object({
      descripcion: Yup.string().required('Este campo es obligatorio. Necesitamos saber más sobre tu proyecto.').max(500, 'Máximo 500 caracteres'),
      nombre: Yup.string().required('El nombre es obligatorio'),
      apellidos: Yup.string().required('Los apellidos son obligatorios'),
      email: Yup.string().email('Email inválido').required('El email es obligatorio'),
      telefono: Yup.string().required('El teléfono es obligatorio'),
      codigoPostal: Yup.string().required('El código postal es obligatorio'),
      direccion: Yup.string().required('La dirección es obligatoria'),
      localidad: Yup.string().required('La localidad es obligatoria'),
      politicaPrivacidad: Yup.boolean().oneOf([true], 'Debes aceptar la política de privacidad')
    }),
    onSubmit: (values) => {
      setCurrentStep(5)
      formSend({ data: values, callBack: submitCallBack, lang, statData })
    }
  });

  const submitCallBack = (isOk) => {
    if (isOk) {
      setCurrentStep(7)
    } else {
      setCurrentStep(6)
    }
  }

  const handleSetOptionValueAndNext = (field, value) => {
    formik.setFieldValue(field, value);
    setTimeout(() => {
      handleNext();
    }, 200);
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className={s.stepContainer}>
            <h4>¿Cuándo quieres empezar el proyecto de tu nueva cocina?</h4>
            <div className={s.optionsContainer}>
              <button
                type="button"
                className={formik.values.tiempoProyecto === 'menos3meses' ? s.selectedOption : s.option}
                onClick={() => handleSetOptionValueAndNext('tiempoProyecto', 'menos3meses')}
              >
                EN MENOS DE 3 MESES
              </button>
              <button
                type="button"
                className={formik.values.tiempoProyecto === 'mas6meses' ? s.selectedOption : s.option}
                onClick={() => handleSetOptionValueAndNext('tiempoProyecto', 'mas6meses')}
              >
                EN MÁS DE 6 MESES
              </button>
              <button
                type="button"
                className={formik.values.tiempoProyecto === 'sinFecha' ? s.selectedOption : s.option}
                onClick={() => handleSetOptionValueAndNext('tiempoProyecto', 'sinFecha')}
              >
                NO TENGO FECHA, ESTOY VALORANDO OPCIONES
              </button>
            </div>
            {formik.touched.tiempoProyecto && formik.errors.tiempoProyecto && (
              <div className={s.error}>{formik.errors.tiempoProyecto}</div>
            )}
          </div>
        );

      case 2:
        return (
          <div className={s.stepContainer}>
            <h4>¿Cuál es tu presupuesto aproximado?</h4>
            <div className={s.optionsContainer}>
              <button
                type="button"
                className={formik.values.presupuesto === '5000_10000' ? s.selectedOption : s.option}
                onClick={() => handleSetOptionValueAndNext('presupuesto', '5000_10000')}
              >
                ENTRE 5.000 Y 10.000 €
              </button>
              <button
                type="button"
                className={formik.values.presupuesto === '10000_20000' ? s.selectedOption : s.option}
                onClick={() => handleSetOptionValueAndNext('presupuesto', '10000_20000')}
              >
                ENTRE 10.000 Y 20.000 €
              </button>
              <button
                type="button"
                className={formik.values.presupuesto === 'mas20000' ? s.selectedOption : s.option}
                onClick={() => handleSetOptionValueAndNext('presupuesto', '20000_')}
              >
                MÁS DE 20.000 €
              </button>
            </div>
            {formik.touched.presupuesto && formik.errors.presupuesto && (
              <div className={s.error}>{formik.errors.presupuesto}</div>
            )}
          </div>
        );

      case 3:
        return (
          <>
            <div className={s.stepContainer}>
              <h4>Cuéntanos un poco más sobre tu proyecto</h4>
              <textarea
                name="descripcion"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="He comprado un piso de segunda mano y quiero cambiar la cocina al completo. Tiene unos 10 metros cuadrados..."
                maxLength={500}
                className={s.textarea}
              />
              {formik.touched.descripcion && formik.errors.descripcion && (
                <div className={s.error}>{formik.errors.descripcion}</div>
              )}
            </div>
            <div className={s.navigationButtom}>
              <div
                onClick={formik.touched.descripcion && !formik.errors.descripcion ? handleNext : null}
                className={[s.nextButton, formik.touched.descripcion && !formik.errors.descripcion ? '' : s.disabled].join(' ')}
              >
                {formik.touched.descripcion && !formik.errors.descripcion ? 'Siguiente' : ''}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={11}
                  height={20}
                  fill="none"
                >
                  <path
                    stroke="#fff"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    d="m1 1 9 9-9 9"
                  />
                </svg>
              </div>

            </div>
          </>
        );

      case 4:
        const isFormValid = Object.keys(formik.values).every(key => formik.touched[key] && !formik.errors[key])
        return (
          <>
            <div className={s.stepContainer}>
              <h4>Déjanos tus datos</h4>
              <div className={s.formGrid}>
                <input
                  type="text"
                  name="nombre"
                  placeholder="Nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.nombre && formik.errors.nombre ? s.inputError : s.input}
                />
                <input
                  type="text"
                  name="apellidos"
                  placeholder="Apellidos"
                  value={formik.values.apellidos}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.apellidos && formik.errors.apellidos ? s.inputError : s.input}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={[formik.touched.email && formik.errors.email ? s.inputError : s.input, s.twoColumns].join(' ')}
                />
                <input
                  type="tel"
                  name="telefono"
                  placeholder="Teléfono"
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.telefono && formik.errors.telefono ? s.inputError : s.input}
                />
                <input
                  type="text"
                  name="direccion"
                  placeholder="Dirección"
                  value={formik.values.direccion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.direccion && formik.errors.direccion ? s.inputError : s.input}
                />
                <input
                  type="text"
                  name="localidad"
                  placeholder="Localidad"
                  value={formik.values.localidad}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.localidad && formik.errors.localidad ? s.inputError : s.input}
                />
                <input
                  type="text"
                  name="codigoPostal"
                  placeholder="Código postal"
                  value={formik.values.codigoPostal}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.touched.codigoPostal && formik.errors.codigoPostal ? s.inputError : s.input}
                />
                <div className={s.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="politicaPrivacidad"
                    id="politicaPrivacidad"
                    checked={formik.values.politicaPrivacidad}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="politicaPrivacidad">He leído, entiendo y acepto la política de privacidad</label>
                </div>
                {formik.touched.politicaPrivacidad && formik.errors.politicaPrivacidad && (
                  <div className={s.error}>{formik.errors.politicaPrivacidad}</div>
                )}
              </div>
            </div>
            <div className={s.navigationButtom}>

              <button
                type="submit"
                className={[s.nextButton, isFormValid ? '' : s.disabled].join(' ')}
                disabled={!isFormValid}
              >
                Enviar
              </button>
            </div>
          </>
        );

      case 5: //Loading
        return (
          <div className={s.stepContainer}>
            <h4>Enviando...</h4>
          </div>
        )
      case 6: //Error
        return (
          <div className={s.stepContainer}>
            <h4>Error</h4>
          </div>
        )
      case 7: //Gracias
        return (
          <div className={s.stepContainer}>
            <h4>El formulario ha sido correctamente enviado, nos pondremos en contacto contigo en breve.</h4>
          </div>
        )
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (currentStep < visibleSteps) {
      if(currentStep + 1 === 3){
        formik.setTouched({
          tiempoProyecto: true,
          presupuesto: true,
        })
      }
      setCurrentStep(currentStep + 1);
      
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentStep === visibleSteps) {
      formik.handleSubmit();
    } else {
      handleNext();
    }
  };


  useLayoutEffect(() => {
    if(stepsFocus[currentStep - 1]){
      const formItem = document.querySelector(`[name="${stepsFocus[currentStep - 1]}"]`)
      if(formItem){
        formItem.focus()
      }
    }
  }, [currentStep])
  return (
    <div className={s.formContainer}>
      <form onSubmit={handleSubmit}>
        <div className={s.navigationTop}>
          {currentStep > 1 && currentStep < 5 && (
            <button type="button" onClick={handlePrev} className={s.prevButton}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="none"
              >
                <circle cx={8} cy={8} r={8} fill="#fff" />
                <path
                  stroke="#474747"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  d="M10.25 12.148 6.04 8l4.21-4.148"
                />
              </svg>
            </button>
          )}
        </div>

        <div className={s.progressBar}>
          {currentStep < 5 && (
            [...Array(visibleSteps)].map((_, index) => (
              <div
                key={index}
                className={`${s.progressDot} ${index + 1 <= currentStep ? s.active : ''}`}
              />
            ))
          )}
        </div>
        {renderStep()}
      </form>
    </div>
  );
};

export default FormSteps;
